import { gql } from '../..';
import { ACCOUNTS_SETTING_FILTER_FIELDS } from './accountsetting';

export const USER_ACCOUNTS_FILTER_FIELDS = gql`
  fragment UserAccountFiltersFields on UserFilters {
  project {
    selectedAccount {
      _id
      _db
    }
    selectedUser {
      _id
      firstName
      lastName
    }
    sort
  }
  workReport {
    dateRange
  }
  coverageReport {
    selectedUsers
  }
  projectMonthly {
    selectedTagsFilter 
    # selectedAccount
    selectedAccount {
      _id
      _db
      name
    }
    selectedAssignment {
      _id
      name
    }
    showWeeks
    selectedUser {
      _id
      firstName
      lastName
    }
    selectedProjectType
    sort
  }
  progressView {
    hideDoneTasks
    status
    dateRange
    selectedUsers
  }
  campaign {
    selectedAccount {
      _id
      _db
      name
    }
    selectedProjectType
  }
  publishItem {
    selectedAccount {
      _id
      _db
      name
    }
    selectedProjectType
  }
  projectDeadlines {
    selectedTagsFilter 
    selectedAccount {
      _id
      _db
      name
    }
    selectedUser {
      _id
      firstName
      lastName
    }
    selectedProjectType
    sort
  }
  doneProjects {
    selectedTagsFilter 
    selectedAccount {
      _id
      _db
      name
    }
    selectedUser {
      _id
      firstName
      lastName
    }
    selectedProjectType
    sort
  }
  task {
    selectedAccount {
      _id
      _db
    }
    selectedUser {
      _id
      firstName
      lastName
    }
    sort
  }
  timeline {
    selectedYear
    selectedAccount {
      _id
      _db
      name
    }
    selectedProjectType
    selectedResourceTypes
    selectedUser {
      _id
      firstName
      lastName
    }
    selectedTagsFilter 
  }
  timePlanner {
    selectedAccount {
      _id
      _db
    }
    selectedUser {
      _id
      firstName
      lastName
    }
  }
  contactService {
    selectedAccount {
      _id
      _db
    }
    selectedService
  }
  landingPage {
    selectedAccount {
      _id
      _db
    }
    selectedService
  }
  email {
    selectedAccount {
      _id
      _db
    }
    selectedService
  }
  form {
    selectedAccount {
      _id
      _db
    }
    selectedService
  }
  segment {
    selectedAccount {
      _id
      _db
    }
    selectedService
  }
  asset {
    selectedAccount {
      _id
      _db
    }
    selectedService
  }
  meeting {
    selectedAccount {
      _id
      _db
    }
  }
  order {
    selectedAccount {
      _id
      _db
    }
  }
  recentUsers {
    _id
    firstName
    lastName
    profileImage
  }
  myPlanning {
    selectedFilter
  }
  customerWorkReport{
    dateRange
    hideUnReportedAssignment
  }
  supplierWorkReport{
    dateRange
  }
  summaryWorkReport{
    dateRange
  }
  recentProjects{
    _id
    _db
    name
  }
}`

export const SELECTED_USER_FILTERS = gql`
  ${USER_ACCOUNTS_FILTER_FIELDS}

  ${ACCOUNTS_SETTING_FILTER_FIELDS}
  
  fragment SelectedFiltersFields on SelectedFilters {
    general {
      selectedAccount
      selectedLocale
      myPlanning {
        selectedNotesFilter
      }
      recentAccounts {
      _id
      _db
      name
      settings{
        ...AccountSettingFieldsHOC
      }
      } 
    }
    accountFilters {
      _db
      accountId
      filters {
        ...UserAccountFiltersFields
      }
    }
  }
`;
